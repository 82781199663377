<template>
  <div>
    <welcome-conference-banner v-if='showConferenceBanner' class='mb-8'/>
    <div class='px-4 lg:px-0'>
      <my-page-score-cmccme
        class='mt-12 mb-24' />
    </div>
    <welcome-sponsor-carousel v-if='showSponsorCarousel' class='mt-8' />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MyPage',
  components: {
    'my-page-score-cmccme': () => import('@/components/my-page/MyPageScoreCmccme.vue'),
    'welcome-conference-banner': () => import('@/components/welcome/WelcomeConferenceBanner.vue'),
    'welcome-sponsor-carousel': () => import('@/components/welcome/WelcomeSponsorsCarousel.vue'),
  },
  props: {
    showConferenceBanner: {
      type: Boolean,
      default: false,
    },
    showSponsorCarousel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('events', [
      'showingEventMyPageConfigurations',
    ]),
    topHtmlContent () {
      return (this.showingEventMyPageConfigurations && this.showingEventMyPageConfigurations.myPageScoreConfigs) 
           ? this.showingEventMyPageConfigurations.myPageScoreConfigs.topHtmlContent 
           : ''
    },
  },
  methods: {
    ...mapActions('myPages', [
      'getMyPageData'
    ]),
  },
  mounted () {
    this.getMyPageData()
  }
}
</script>

<style lang='scss' scoped>
  .logout-button {
    transition: all 0.3s ease-in-out;
  }
</style>
